import React from "react"
import "./template.scss"
import mapImage from "../../assets/map.png"
import tollTagsImg from "../../assets/TollTags.jpg"

const Header = () => {
  return (
    <nav className="tp-nav">
      <a aria-current="page" className="" href="/">
        <div className="tp-logo"></div>
      </a>

      <a
        href="https://www.texpresslanes.com/"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        What are TEXpress Lanes
      </a>
      <a
        href="https://www.texpresslanes.com/maps/texpress-lanes-map"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        Where are TEXpress Lanes
      </a>
      <a
        href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        How to Use TEXpress Lanes
      </a>
    </nav>
  )
}

// A section with a background
const SectionWithBg = () => {
  return (
    <section className="section-with-bg">
      <div className="flex-container contain">
        <div className="left-col">
          <h2 className="tp-header">
            Enter the TEXpress Lanes Summer Sweepstakes for a chance to win one
            year of FREE tolls on the LBJ, NTE &amp; NTE 35W TEXpress Lanes!
          </h2>
          <p className="text">
            You want to get moving and be where you need to be. We ensure that
            happens by providing a safe, speedy, and reliable commute.
          </p>
          <p className="text">
            And now, we’re giving five lucky drivers a full year of free tolls —
            you don’t want to miss this!
          </p>
          <p className="text">
            Offer valid only on the LBJ/NTE/NTE 35W TEXpress. For full Terms
            &amp; Conditions,{" "}
            <a
              href="https://www.texpresslanes.com/texpress-lanes-summer-sweepstakes-official-rules-22/"
              target="_blank"
              rel="noreferrer"
            >
              please click here
            </a>
            .{" "}
          </p>
        </div>

        <div className="right-col hide-on-mobile"></div>
      </div>

      <div className="car-img"></div>
    </section>
  )
}

const SectionRow = props => {
  return (
    <div className="section-row contain">
      <div className="left-col">
        <div className="red-line-img"></div>
        <h3 className="question">{props.question}</h3>
      </div>
      <div className="right-col">
        <p className="response">{props.response}</p>
      </div>
    </div>
  )
}

const Section2 = () => {
  return (
    <section className="section2">
      <SectionRow
        question="What Are The LBJ/NTE/NTE 35W TEXpress Lanes?"
        response="The LBJ/NTE/NTE 35W TEXpress Lanes are unique toll lanes built within
      existing highways to accommodate more traffic volume. Unlike
      traditional toll roads, the price changes based on real-time traffic
      conditions in order to reduce congestion and keep everyone moving at
      least 50 MPH."
      />

      <SectionRow
        question="How Can I Use the LBJ/NTE/NTE 35W TEXpress Lanes?"
        response="Got a TollTag, TxTag or EZ TAG? You're ready to use the LBJ/NTE/NTE 35W TEXpress Lanes and enter the sweepstakes promotion!"
      />
    </section>
  )
}

const Footer = () => {
  return (
    <footer className="tp-footer">
      <a
        href="https://www.facebook.com/TEXpressLanes"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="fb-icon"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
      </a>
      <span className="regular-font"> | </span>
      <a
        href="https://twitter.com/texpresslanes"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="twitter-icon"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
      </a>
      <span className="regular-font"> | </span>
      <a
        href="https://www.texpresslanes.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <span className="regular-font"> | </span> ©2021 LBJ/NTE/NTE 35W TEXpress
      Lanes <span className="regular-font"> | </span> All Rights Reserved
    </footer>
  )
}

const MapSection = () => {
  /* 
    header
    par
    par
    img
    p
    anchor
    */

  return (
    <section className="map-section">
      <h2 className="tp-header">
        Where Can I Use The LBJ/NTE/NTE35W TEXpress Lanes?
      </h2>
      <p className="text">
        Sweepstakes promotion valid only on our lanes highlighted blue on the
        map below:
      </p>
      <p className="text">
        North Tarrant Express (I-820, SH 183 and SH 121I-35W) & LBJ Express
        (I-635)
      </p>
      <img
        src={mapImage}
        className="image"
        alt="North Texas Texpress Lanes System Map"
      />
      <p className="text">
        Sweepstakes promotion valid only on our lanes highlighted blue on the
        map.
      </p>
      <a className="tp-btn" href="#">
        Enter now
      </a>
    </section>
  )
}

const TransponderLocation = () => {
  /*
    p
    p
    image with 3 cards
    p
    p

    div w/ links  */
  return (
    <section className="transponder-location">
      <p className="text">Where is my toll transponder number?</p>
      <p className="text">
        Here are two ways you can locate your toll transponder number:
      </p>
      <br />
      <img
        src={tollTagsImg}
        className="toll-tags-img"
        alt="ways you can locate your toll transponder number"
      />
      <p className="text">
        Refer to the images above to find the information printed on your toll
        tag transponder on your vehicle's windshield. Your toll transponder
        number is printed on the side facing the driver in the vehicle.
      </p>
      <br />
      <p className="text">
        You can also find your toll transponder number by logging into your
        respective toll transponder account:
      </p>
      <div className="links">
        <a href="https://csc.ntta.org/olcsc/" target="_blank">
          Visit My TollTag Account
        </a>{" "}
        <span className="hide-on-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">
          Visit My TxTag Account
        </a>{" "}
        <span className="hide-on-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="https://www.hctra.org/Login" target="_blank">
          Visit My EZ TAG Account
        </a>
      </div>
    </section>
  )
}

const IndexPage = () => (
    <div className="template">
      <Header />
      <SectionWithBg />
      <Section2 />
      <MapSection />
      <TransponderLocation />
      <Footer />
    </div>
)

export default IndexPage
